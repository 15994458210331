.AccountName-root {
    font-family: 'Oswald';
    font-size: 12px;
    display: flex;
    flex-flow: row;
}

.AccountName-root > span {
    background-color: mediumaquamarine;
    border-radius: 2px;
    padding-left: 2px;
    padding-right: 2px;
    margin-left: 2px;
    color: darkslategrey;
    user-select: none;
}

.AccountName-part {
    min-width: 6ch;
}