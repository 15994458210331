.ListItem-user {
  display: flex;
  flex-direction: row;
  align-items: flex-start !important;
  max-width: 600px;
}

.ListItem-user > div:nth-child(1) {
  flex-grow: 1;
}

.Form-full {
  width: 100%;
  max-width: 600px;
}

.Form-auth-dropdown {
  width: 150px;
}