.Transaction-Description {
    max-width: 800px;
}

.TxEdit-page {
    margin-top: 6px;
}

.Split-Form {
    padding: 8px;
    border: solid 1px lightgray;
    border-radius: 4px;
    margin-top: 4px;
    max-width: 800px;
    background-color: rgb(248, 248, 248);
}

.Split-Form-Focused {
    border: solid 1px darkslategray;
    background-color: white;
}

.Split-Form div {
    margin-bottom: 1px;
}

.Split-Description {
    max-width: 800px;
}

.Split-DateValue {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.Split-Date {
    max-width: 140px;
    min-width: 140px !important;
}

.Split-Date button {
    padding: 6px;
}

.Split-Date .MuiOutlinedInput-adornedEnd {
    padding-right: 2px;
}

.Split-Date .MuiInputAdornment-positionEnd {
    margin-left: 2px;
}

.Split-Account {
    max-width: 800px;
}

.actionInProgress {
    animation: colorAnimation 1s alternate infinite ease-in;
}

@keyframes colorAnimation {
    to {transform: scale(1);}
    from {transform: scale(1.4); }
}

.Transaction-Actions {
    margin: 10px;
    margin-top: 15px;
}

.Transaction-Actions > * {
    margin-right: 20px !important;
}

.Transaction-Simplified-DateDescription {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.Transaction-Simplified .Transaction-Description {
    margin-bottom: 4px;
    margin-left: 4px;
}

.Transaction-Simplified-Value {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2px;
}

.Transaction-Simplified-Flow {
    margin-top: 10px;
    margin-bottom: 20px;
}

.Transaction-Simplified-ValuesGroup > * {
    margin-top: 8px !important;
}