.App-root {
  display:flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.content {
  flex-grow: 1;
  padding: 2px;
  overflow: scroll;
}

.content_margin {
  margin: 4px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.myform fieldset {
  border: 0;
  padding: 0;
}

.myform {
  margin-right: 6px;
  margin-left: 6px;
}

.myform >fieldset > div {
  margin-top: 5px;
  margin-bottom: 15px;
}

main {
  display: flex;
  flex-direction: column;
}

.horizontal {
  display: flex;
  flex-direction: row;
}
.spacerHorizontal {
  margin-left: auto;
}

.report-canvas {
  min-height: 400px;
  flex-grow: 1;
  margin: 5px;
}

.grow {
  flex-grow: 1;
}
.right {
  text-align: right;
}

.d3_star_slice {
  shape-rendering: geometricPrecision;
}
.d3_star_slice:hover {
  stroke: black;
  stroke-width: 0.5px;
}

.d3_star_root text {
  fill: black;
}

.d3_star_mid_value {
  fill: darkgray;
  font-weight: bold;
}

.d3_bars_group rect {
}

.d3_bars_group_highlighted rect {
  stroke: black;
  stroke-width: 0.5px;
}

.d3_bars_group text {
  transition: opacity 0.3s ease;
  fill: black;
  opacity: 0;
  font-weight: bold;
}

.d3_bars_group_highlighted text {
  opacity: 1;
}

.d3_tooltip {
  opacity: 1;
  transition: opacity 0.3s ease;
}
.d3_tooltip rect {
  fill: lightgray;
  fill-opacity: 0.8;
}

.d3_hidden {
  opacity: 0 !important;
}

.Page-narrow {
  max-width: 800px;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.d3_top_marker {
  transition: all 0.2s ease;
  stroke-linecap: round;
  stroke-width: 1px;
  stroke-dasharray: 10 5;
  stroke:black; fill:none;
  opacity: 0.5;
}