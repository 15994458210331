.where {
  flex-grow: 1;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 5px;
  min-height: 40px;
}

.where-ledger {
  height: 24px;
}

.where-account {
  white-space: nowrap;
  font-family: 'Oswald';
  font-size: 90% !important;
}

.AppBar {
  flex-grow: 0;
  position: 'relative !important'
}

.BottomNav button {
}

.narrowButton {
  margin: 0px !important;
  min-width: 20px !important;
}

.narrowButtonDisabled {
  color: lightgray !important;
  margin: 0px !important;
  min-width: 20px !important;
}

.narrowButtonHidden {
  display: none !important;
}

.BottomNavigationAction-common {
}
.BottomNavigationAction-hidden {
  display: none !important;
}
.BottomNavigationAction-progress svg {
  animation: button-rotate 2s infinite linear;
  color: darkslategray;
}

.BottomNavigationAction-disabled {
  color: lightgray !important;
}

@keyframes button-scale {
  0% {
      transform: scale(1.0);
  }
  50% {
  }
  100% {
      color: darkslategray;
      transform: scale(1.6);
  }
}

@keyframes button-rotate {
  0% {
      transform: rotate(0deg);
  }
  25% {
    transform: rotate(-30deg);
  }
  75% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}