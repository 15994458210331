.accountRight > div{
    text-align: right;
}

.accountRight > div:nth-child(1) > span {
    background-color: white;
}

.accountRight > div:nth-child(2) > span {
    background-color: rgb(222, 222, 222);
}

.accountRight > div:nth-child(2) > span > span:nth-child(2) {
    color: rgb(158, 165, 165);
}

.accountRight {
    right: 4px !important;
}

.table-value {
    text-align: right;
}

.transactionsListSeparator {
    margin-top: auto;
}

.transactionsContainer {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
}

.Account-fab {
    position: absolute !important;
    bottom: 75px;
    right: 100px;
}

.Account-root {
    display: flex;
    flex-grow: 1;
    overflow-y: scroll;
}
.Account-downloadQIF {
    display: none;
}