.AppDrawer-top {
    background: url( "/uc2-logo-64.png" ) no-repeat;
    background-position: left center;
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-left: 15px;
    background-size: 36px 36px;
    height: 36px;
    padding-left: 40px;
}

.AppDrawer-top > .container-brand {
    height: 20px;
    margin: 0;
    margin-top: 12px;
    padding: 0;
    padding-left: 10px;
    font-weight: 400;
    font-size: 1rem;
    color: rgb(62, 62, 62);
}

.AppDrawer-top > .build-no {
    font-family: 'B612 Mono';
    font-weight: normal;
    font-weight: bold;
    font-size: 50%;
    padding-left: 20px;
    color: rgb(62, 62, 62);
    opacity: 0.5;
    letter-spacing: -0.75px;
}