.BalanceEntry {
    border: solid 1px darkgray;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    padding-left: 1px;
    padding-right: 4px;
    height: 25px;
    margin-bottom: 2px;
}

.be-date {
    height: 15px;
    width: 60px;
    font-size: x-small;
    letter-spacing: -1px;
}
.be-val {
    margin-left: auto;
}

.be-hidden {
    opacity: 0.0;
}

.be-desc {
    margin-left: 20px;
    font-size: small !important;
}