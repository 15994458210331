.monetaryValue .commodity{
    color: gray;
    font-size: 90%;
    padding-right: 2px;
}

.monetaryValue .value {
    font-family: 'B612 Mono', monospace;
    letter-spacing: -0.75px;
}

.monetaryValue .value-negative {
    color: darkred;
}

.monetaryValue .value span {
    font-size: 75%;
}

.monetaryValue {
    padding: 2px;
    border-radius: 2px;
}

.mv-normal-favorable {
    color: black;
}
.mv-normal-unfavorable {
    color: brown;
}
.mv-reversed-favorable {
    color:darkblue;
}
.mv-reversed-unfavorable {
    color: chocolate;
}
