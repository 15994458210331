.AccountTree-label {
    display: flex;
    flex-direction: row;
}

.AccountTree-label div[data-role="AcountTree-label-name"] {
    flex-grow: 1;
}

.AccountTree-label :nth-child(3) {
    font-size: 90%;
    align-self: center;
    margin-right: 2px;
}