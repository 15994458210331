html, body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

fieldset[disabled] label {
  color: lightgray;
}

form .notification {
  padding-left: 10pt;
}
.app-content {
  padding-top: 2px;
}
.spacer {
  margin-left: auto;
  display: inline-block;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.mono {
  font-family: 'B612 Mono';
  letter-spacing: -0.75px;
}

.app-fadein {
  animation: app-fadein 2s;
}

@keyframes app-fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}