.txs-date {
  font-size: x-small;
  letter-spacing: -1px;
}

.txs-ListItem {
  padding-left: 0px !important;
}

.txs-desc {
  padding-left: 4px;
}

.no-transactions {
  margin-left: 20px;
  margin-top: 20px;
}