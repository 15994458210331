.ReportsEdit-container {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
}

.ReportsEdit-Drawer form {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}
