.TxTableLarge {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 2px;
}

.TxTableLarge th {
  text-align: left;
}

.TxTableLarge th:nth-child(5),th:nth-child(6) {
  text-align: right;
}
.TxTableLarge td:nth-child(5),td:nth-child(6) {
  text-align: right;
}


.TxTableLarge th, td {
  border: 1px solid darkgray;
}


.TxTableLarge tbody tr:nth-child(odd) {
  background-color: #dae5f4;
}


.TxTableLarge tbody td:nth-child(1) {
  white-space: nowrap;
  width: 10ch;
  font-size: x-small;
}
.TxTableLarge tbody td:nth-child(2) {
  white-space: nowrap;
  width: 4ch;
  font-size: x-small;
}